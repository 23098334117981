import StripColumnsContainer_DefaultComponent from '../components/StripColumnsContainer/viewer/skinComps/Default/Default.skin';


const StripColumnsContainer_Default = {
  component: StripColumnsContainer_DefaultComponent
};


export const components = {
  ['StripColumnsContainer_Default']: StripColumnsContainer_Default
};

